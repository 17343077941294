import { BrowserRouter, Switch, Route } from 'react-router-dom'
import loadable from '@loadable/component'
import GlobalStyle from 'main/GlobalStyle'
import Public from 'components/Routes/Public'
import Private from 'components/Routes/Private'
import Template from 'components/Template'
import TemplateLogin from 'components/TemplateLogin'
import PersoanlizedTemplateLogin from 'components/PersoanlizedTemplateLogin'

import ContextProvider from 'contexts/context'

import NoMatch from 'pages/NoMatch'
import Login from 'pages/Login'
import ForgotPassword from 'pages/ForgotPassword'
import RecoverPassword from 'pages/RecoverPassword'
import RegisterPassword from 'pages/RegisterPassword'
import Profile from 'pages/Profile'

// const NoMatch = loadable(() => import('pages/NoMatch'))
// const Login = loadable(() => import('pages/Login'))
// const ForgotPassword = loadable(() => import('pages/ForgotPassword'))
// const RecoverPassword = loadable(() => import('pages/RecoverPassword'))
// const RegisterPassword = loadable(() => import('pages/RegisterPassword'))
// const Profile = loadable(() => import('pages/Profile'))

import Index from 'pages/Index'

// const Index = loadable(() => import('pages/Index'))

import Groups from 'pages/Groups'
import Reports from 'pages/Reports'
import Report from 'pages/Report'

// const Groups = loadable(() => import('pages/Groups'))
// const Reports = loadable(() => import('pages/Reports'))
// const Report = loadable(() => import('pages/Report'))

import Cards from 'pages/Cards'
import CardsStore from 'pages/CardsStore'
import CardsUpdate from 'pages/CardsUpdate'
import CardsUpdateImage from 'pages/CardsUpdateImage'

// const Cards = loadable(() => import('pages/Cards'))
// const CardsStore = loadable(() => import('pages/CardsStore'))
// const CardsUpdate = loadable(() => import('pages/CardsUpdate'))
// const CardsUpdateImage = loadable(() => import('pages/CardsUpdateImage'))

import Users from 'pages/Users'
import UsersStore from 'pages/UsersStore'
import UsersUpdate from 'pages/UsersUpdate'
import ReportsUser from 'pages/UserReports'
import ReportsUserStore from 'pages/ReportsUserStore'
import ReportsUserUpdate from 'pages/ReportsUserUpdate'

// const Users = loadable(() => import('pages/Users'))
// const UsersStore = loadable(() => import('pages/UsersStore'))
// const UsersUpdate = loadable(() => import('pages/UsersUpdate'))
// const ReportsUser = loadable(() => import('pages/UserReports'))
// const ReportsUserStore = loadable(() => import('pages/ReportsUserStore'))
// const ReportsUserUpdate = loadable(() => import('pages/ReportsUserUpdate'))

import NewUserReportsCollection from 'pages/UserReports/Collections/New'

// const NewUserReportsCollection = loadable(() => import('pages/UserReports/Collections/New'))

import ReportsCollection from 'pages/ReportsCollection'
import NewReportsCollection from 'pages/ReportsCollection/New'
import UpdateReportsCollection from 'pages/ReportsCollection/Update'
import ReportsCollectionItems from 'pages/ReportsCollection/Reports'
import UpdateReportsCollectionItems from 'pages/ReportsCollection/Reports/Update'

// const ReportsCollection = loadable(() => import('pages/ReportsCollection'))
// const NewReportsCollection = loadable(() => import('pages/ReportsCollection/New'))
// const UpdateReportsCollection = loadable(() => import('pages/ReportsCollection/Update'))
// const ReportsCollectionItems = loadable(() => import('pages/ReportsCollection/Reports'))
// const UpdateReportsCollectionItems = loadable(() => import('pages/ReportsCollection/Reports/Update'))

import Companies from 'pages/Companies'
import NewCompany from 'pages/Companies/New'
import UpdateCompany from 'pages/Companies/Update'

// const Company = loadable(() => import('pages/Companies'))
// const NewCompany = loadable(() => import('pages/Companies/New'))
// const UpdateCompany = loadable(() => import('pages/Companies/Update'))

import Analytics from 'pages/Analytics'

// const Analytics = loadable(() => import('pages/Analytics'))

import Error from 'pages/Error'
import PrivacyPolitics from 'pages/PrivacyPolitics'

// const Error = loadable(() => import('pages/Error'))

const App = () => {
	return (
		<>
			<GlobalStyle />
			<ContextProvider>
				<BrowserRouter>
					<Switch>
						<Route path="/politica-de-privacidade"><PrivacyPolitics /></Route>
						<Public path="/login" component={TemplateLogin(Login)} redirect="/dashboard" />
						<Public path="/esqueci-senha" component={TemplateLogin(ForgotPassword)} redirect="/dashboard" />
						<Public path="/registrar-senha/:token" component={TemplateLogin(RegisterPassword)} redirect="/dashboard" />
						<Public path="/recuperar-senha/:token" component={TemplateLogin(RecoverPassword)} redirect="/dashboard" />
						<Private path="/profile" component={Template(Profile)} redirect="/login" />

						<Private
							path="/grupos/:group_id/relatorios/:report_id/datasets/:dataset_id"
							component={Template(Report)}
							redirect="/login"
						/>
						<Private path="/grupos/:group_id/relatorios" component={Template(Reports)} redirect="/login" />
						<Private path="/grupos" component={Template(Groups)} redirect="/login" />

						<Private path="/cards/:id/upload" component={Template(CardsUpdateImage)} redirect="/login" />
						<Private path="/cards/:id/editar" component={Template(CardsUpdate)} redirect="/login" />
						<Private path="/cards/cadastrar" component={Template(CardsStore)} redirect="/login" />
						<Private path="/cards" component={Template(Cards)} redirect="/login" />

						<Private path="/relatorios" component={Template(Reports)} redirect="/login" />

						<Private path="/usuarios/:id/relatorios/:report_id/editar" component={Template(ReportsUserUpdate)} redirect="/login" />
						<Private path="/usuarios/:id/relatorios/cadastrar" component={Template(ReportsUserStore)} redirect="/login" />
						<Private path="/usuarios/:id/colecoes/cadastrar" component={Template(NewUserReportsCollection)} redirect="/login" />
						<Private path="/usuarios/:id/relatorios" component={Template(ReportsUser)} redirect="/login" />
						<Private path="/usuarios/:id/editar" component={Template(UsersUpdate)} redirect="/login" />
						<Private path="/usuarios/cadastrar" component={Template(UsersStore)} redirect="/login" />
						<Private path="/usuarios" component={Template(Users)} redirect="/login" />

						<Private path="/colecoes/:collectionId/relatorios/:id/editar" component={Template(UpdateReportsCollectionItems)} redirect="/login" />
						<Private path="/colecoes/:id/relatorios" component={Template(ReportsCollectionItems)} redirect="/login" />
						<Private path="/colecoes/:id/editar" component={Template(UpdateReportsCollection)} redirect="/login" />
						<Private path="/colecoes/cadastrar" component={Template(NewReportsCollection)} redirect="/login" />
						<Private path="/colecoes" component={Template(ReportsCollection)} redirect="/login" />

						<Private path="/empresas/:id/editar" component={Template(UpdateCompany)} redirect="/login" />
						<Private path="/empresas/cadastrar" component={Template(NewCompany)} redirect="/login" />
						<Private path="/empresas" component={Template(Companies)} redirect="/login" />
						
						<Private path="/analytics" component={Template(Analytics)} redirect="/login" />
						<Private path="/error" component={Template(Error)} redirect="/login" />

						<Public path="/:slug/registrar-senha/:token" component={PersoanlizedTemplateLogin(RegisterPassword)} redirect="/dashboard" />
						<Public path="/:slug/recuperar-senha/:token" component={PersoanlizedTemplateLogin(RecoverPassword)} redirect="/dashboard" />
						<Public path="/:slug/esqueci-senha" component={PersoanlizedTemplateLogin(ForgotPassword)} redirect="/" />
						<Public path="/:slug" component={PersoanlizedTemplateLogin(Login)} redirect="/" />

						<Private path="/" component={Index} redirect="/login" />
						<Route path="*"><NoMatch /></Route>
					</Switch>
				</BrowserRouter>
			</ContextProvider>
		</>
	)
}

export default App
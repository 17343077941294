import { useContext, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Topbar, LeftSideContainer, NavigationContainer, NavigationLink,
  NavigationText, Logo, RightSideContainer, ActionsContainer, Divider,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  DropdownText, UserContainer, UserName, UserPhoto, ShowMobile,
  TopbarMenuDesktop, TopbarMenuMobile
} from './styled'

import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react"

import { FiMoreHorizontal, FiMaximize2, FiChevronLeft, FiRotateCcw, FiMaximize, FiPrinter  } from "react-icons/fi"

import { BiLogOut } from 'react-icons/bi'

import { BeautifulName } from 'beautiful-name'

import { FaUsers, FaChartArea, FaImages, FaLayerGroup, FaIndustry, FaHome, FaBusinessTime, FaObjectUngroup } from 'react-icons/fa'
import { AiOutlineUser, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

import BtnOrange from 'components/Button/BtnOrange'

import { FaBars } from "react-icons/fa"
import { Context } from 'contexts/context'
import { logout } from 'services/auth'

import api from "../../services/api/api";


const Component = (props) => {
  const history = useHistory()

  const [isReportPage, setIsReportPage] = useState(false)
  const [isInitialPage, setIsInitialPage] = useState(false)

  const { user, report, isLoaded, isJustOneReport, hasMobile, setHasMobile } = useContext(Context)

  const handleLogout = async () => {
    try {
      await api.delete("/users/logout")
    } catch (error) {

    } finally {
      logout(history, user.company?.slug)
    }
  }

  useEffect(() => {
    const pathNameSplit = history.location.pathname.split("/")

    console.log(pathNameSplit)

    setIsInitialPage(pathNameSplit[1] === "relatorios")
    setIsReportPage(pathNameSplit.includes("grupos") && pathNameSplit.includes("relatorios") && pathNameSplit.includes("datasets"))
  }, [history])

  return (
    <div style={{ backgroundColor: isReportPage ? "#04110A" : "#fff" }} id='panel'>
      <div className='panelFlex'>
        {(!isInitialPage && (!isJustOneReport || !isReportPage)) && (
          <BtnOrange
            onClick={() => history.goBack()}
            isLoading={false}
            style={{
              width: "23px",
              height: "23px",
              padding: 0,
              backgroundColor: isReportPage ? "#04110A" : "#fff"
            }}
          >
            <FiChevronLeft size={20} color={!isReportPage ? "#04110A" : "#fff"} />
          </BtnOrange>
        )}
      </div>

      <div className='panelFlex'>
        <div>
          {isLoaded && (
            <BtnOrange
              onClick={() => report?.fullscreen()}
              isLoading={false}
              style={{
                width: "23px",
                height: "23px",
                marginRight: "1rem",
                padding: 0,
                backgroundColor: isReportPage ? "#04110A" : "#fff"
              }}
            >
              <FiMaximize2 size={20} color={!isReportPage ? "#04110A" : "#fff"} />
            </BtnOrange>
          )}
        </div>

        <Dropdown>
          <DropdownToggle color={"#000"}>
            <BtnOrange
              isLoading={false}
              style={{
                width: "23px",
                height: "23px",
                padding: 0,
                backgroundColor: isReportPage ? "#04110A" : "#fff"
              }}
            >
              <FiMoreHorizontal size={20} color={!isReportPage ? "#04110A" : "#fff"} />
            </BtnOrange>
          </DropdownToggle>

          <DropdownMenu x="0px" y="28px">
            {isLoaded && (
              <>
                {window.innerWidth <= 600 ? (
                  <DropdownItem onClick={() => {
                    if (window.innerHeight > window.innerWidth) setHasMobile(!hasMobile)
                  }}>
                    <FiMaximize fontSize={20} color={"black"} />
                    <DropdownText style={{ color: "black" }}>Versão {hasMobile ? "Desktop" : "Celular"}</DropdownText>
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={() => report?.print()}>
                  <FiPrinter  fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Imprimir</DropdownText>
                </DropdownItem>
                )}

                <DropdownItem onClick={() => report?.refresh()}>
                  <FiRotateCcw fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Atualizar</DropdownText>
                </DropdownItem>

                <div style={{ height: ".1px", width: "95%", backgroundColor: "#04110A", opacity: .5, margin: "5px auto" }} />
              </>
            )}

            <DropdownItem onClick={() => history.push("/profile")}>
              <AiOutlineUser fontSize={20} color={"black"} />
              <DropdownText style={{ color: "black" }}>Perfil</DropdownText>
            </DropdownItem>

            <DropdownItem onClick={() => handleLogout(history)}>
              <BiLogOut fontSize={20} color={"black"} />
              <DropdownText style={{ color: "black" }}>Sair</DropdownText>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )

}

export default Component

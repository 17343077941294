import { useContext, useEffect, useState } from 'react'
import { FaEdit } from "react-icons/fa"
import { HiOutlineDocumentReport } from "react-icons/hi"

import { Context } from 'contexts/context'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'

import Td from 'components/Td'

import api from 'services/api/api'

export default function ReportsCollection() {
    const { firmId } = useContext(Context)

    const [reportsCollections, setReportsCollections] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/collections/by-firm`)

                setReportsCollections(data)
            } catch(error) {

            }
          })()
    }, [firmId])

    return (
        <>
        <TitleBar label="Grupos" currentPage="" />

        <Content>
            <Card>
                <CardTitle title="Listagem">
                    <LinkBlue to="/colecoes/cadastrar">Cadastrar</LinkBlue>
                </CardTitle>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th>Nome</th>
                            <th width="100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportsCollections.map(reportsCollection =>
                            <tr key={reportsCollection.id}>
                                <td>{reportsCollection.id}</td>
                                <td>{reportsCollection.name}</td>

                                <Td>
                                    <SmLinkBlue to={`/colecoes/${reportsCollection.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>

                                    <SmLinkBlue to={`/colecoes/${reportsCollection.id}/relatorios`}>
                                        <HiOutlineDocumentReport />
                                    </SmLinkBlue>
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>
    </>
    )
}
import axios from 'axios'
import config from 'config'

import { getToken, getUniqueID, getfirmID } from 'services/auth'

const api = axios.create({ baseURL: config.envApi })

api.interceptors.request.use(
    async config => {
        const token = getToken()
        const uniqueId = getUniqueID()
        const firmId = getfirmID()

        if (token) config.headers.Authorization = `${token}`
        if (uniqueId) config.headers.uniqueId = `${uniqueId}`
        if (firmId) config.headers.firmId = `${firmId}`

        return config
    }
)

export default api

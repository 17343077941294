import { Login, Form, Logo, LoginBox } from './styled'

const Base = ChildrenComponent => {

  const ComponentBase = props => {
    return (
      <Login>
        <Form>
          <LoginBox>
            <Logo>
              <img src="/logo-big.png" alt="exph" />
            </Logo>
            <ChildrenComponent {...props} />
          </LoginBox>
        </Form>
      </Login>
    )
  }

  return ComponentBase
}

export default Base

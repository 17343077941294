import { useEffect, useContext, useState } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import { Context } from 'contexts/context'

import LoadingScreen from 'components/LoadingScreen'
import { handleReportsByUserWithCard } from 'services/api/reports'

import api from "../../services/api/api"

import { getfirmID, setfirmID } from 'services/auth'

import { getUser, userAuthenticated, logout } from 'services/auth'

const Component = ({ component: Component, redirect, ...rest }) => {
  const [error, setError] = useState(false)

  const { user, setUser, setIsJustOneReport, setFirmId } = useContext(Context)

  const history = useHistory()

  useEffect(() => {
    (async () => {
      try {
        const data = await getUser()

        localStorage.setItem('company', JSON.stringify(data.company))

        setUser(data)
      } catch(error) {
        if (error.response?.status === 401) {
          if (localStorage.getItem("company") === "undefined") localStorage.removeItem('company')

          const company = JSON.parse(localStorage.getItem("company"))


          return logout(history, company?.slug)
        }

        setError(true)
      }
    })()
  }, [])

  useEffect(() => {
    const handleOneReport = async () => {
      const reports = await handleReportsByUserWithCard()

      if (reports.length === 1) {
        setIsJustOneReport(true)

        const report = reports[0]

        return history.push(`/grupos/${report.group_id}/relatorios/${report.report_id}/datasets/${report.dataset_id}`)
      }
    }

    const handleFirms = async () => {
      const firmId = getfirmID()

      if (firmId) {
        setFirmId(firmId)
      } else {
        try {
          const response = await api.get("/firms")

          setFirmId(response.data[1].id)
          setfirmID(response.data[1].id)
        } catch (error) {}
      }
    }

    if (user?.roles !== "admin") {
      handleOneReport()
    } else {
      handleFirms()
    }
  }, [user])

  return (
    <>
      {(user || error) ? (
        <Route {...rest} render={props => userAuthenticated() ? <Component error={error} {...props} /> : <Redirect to={redirect} />} />
      ) : <LoadingScreen loading />}
    </>
  )
}

export default Component
import { useContext, useEffect, useState } from 'react'

import { Context } from 'contexts/context'

import { handleUsers } from 'services/api/users'

import Component from './Component'

const Page = () => {
    const { firmId } = useContext(Context)

    const [data, setData] = useState([])

    useEffect(() => {
        (async () => {
            try {
                setData(await handleUsers())
            } catch(error) {}
        })()
    }, [firmId])

    return <Component data={data} />
}

export default Page
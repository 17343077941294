import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Login, Form, Logo, LoginBox } from './styled'

import { handleCompanieshowSlug } from 'services/api/empresas'

const Base = ChildrenComponent => {

  const ComponentBase = props => {
    const [data, setData] = useState()
    
    const history = useHistory()
    const params = useParams()

    useEffect(() => {
      const handleData = async () => {
        try {
          const empresa = await handleCompanieshowSlug(params.slug)
          setData(empresa)
        } catch(error) {
          history.push("/")
        }
      }

      handleData()
    }, [])

    if (!data) return null

    return (
      <Login fundo={`${process.env.REACT_APP_URL_API}/${data.background_url}`}>
        <Form>
          <LoginBox primary_color={data.primary_color}>
            <Logo>
              <img src={`${process.env.REACT_APP_URL_API}/${data.logo_url}`} alt="analitycdbi" />
            </Logo>
            <ChildrenComponent {...props} data={data} />
          </LoginBox>
        </Form>
      </Login>
    )
  }

  return ComponentBase
}

export default Base

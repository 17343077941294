import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Context } from 'contexts/context'

import { handlePowerBiGroups } from 'services/api/powerbi'

import Component from './Component'

const Page = () => {
    const { firmId } = useContext(Context)

    const params = useParams()
    const [data, setData] = useState([])

    useEffect(() => {
        (async () => setData(await handlePowerBiGroups()))()
    }, [firmId])

    return <Component data={data} params={params} />
}

export default Page
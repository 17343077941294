import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import Upload from 'components/Form/Upload'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import BtnOrange from 'components/Button/BtnOrange'
import CardBody from 'components/CardBody'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import ColorPicker from 'components/Form/ColorPicker'
import BtnWhite from 'components/Button/BtnWhite'
import Modal from 'components/Modal'

import { handleCompanieshow, handleCompanyUpdate, handleCompanyDelete, handleCompanyUpdateLogo, handleCompanyUpdateBackground } from 'services/api/empresas'

import { slugMask } from 'utils'

const validation = yup => ({
  name: yup.string()
    .required('Campo obrigatório'),
  slug: yup.string()
    .required('Campo obrigatório'),
  email: yup.string()
    .required('Campo obrigatório')
})

const NewReportCollection = () => {
    const [data, setData] = useState({})

    const [visibleModal, setVisibleModal] = useState(false)
    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const [messagesFile, setMessagesFile] = useState('')
    const [done, setDone] = useState(true)
    const [errors, setErrors] = useState([])

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
      initialValues: { 
        name: data.name, 
        slug: data.slug, 
        email: data.email,
        primary_color: data.primary_color, 
        secondary_color: data.secondary_color
      },
      enableReinitialize: true,
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => handleCompanyUpdate(history, formik.values, params.id, setMessages)
    })

    useEffect(() => {
      (async () => {
        try {
          setDone(true)
          setData(await handleCompanieshow(params.id))
          setDone(false)
        } catch(error) {}
      })()
    }, [params.id])

    const handleDelete = async () => handleCompanyDelete(params.id, history)

    const files = async files => {
      setMessagesFile('')
      setErrors([])
      setDone(true)
      await handleCompanyUpdateLogo(files[0], params.id)
      setData(await handleCompanieshow(params.id))
      setDone(false)
    }

    const filesFundo = async files => {
      setMessagesFile('')
      setErrors([])
      setDone(true)
      await handleCompanyUpdateBackground(files[0], params.id)
      setData(await handleCompanieshow(params.id))
      setDone(false)
    }

    return (
        <>
        <TitleBar label="Empresas" currentPage="" />

        <Content>
          <Card>
            <CardTitle title="Editar">
              <BtnOrange onClick={() => setVisibleModal(true)}>Excluir</BtnOrange>
            </CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <Input name="name" label="Nome" formik={formik} />
                  <Input mask={slugMask} name="slug" label="Slug" formik={formik} />
                  <Input name="email" label="email" formik={formik} />

                  <ColorPicker name="primary_color" label="Cor primária" formik={formik} />
                  <ColorPicker name="secondary_color" label="Cor secundária" formik={formik} />
                  
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>

          <Card>
            <CardTitle title="Logo"></CardTitle>
            <Content>
              <CardBody>
                <Upload
                  onUpload={files}
                  fileUrl={data.logo_url}
                  done={done}
                  errors={errors}
                  setErrors={setErrors}
                />
                <p>{messagesFile}</p>
              </CardBody>
            </Content>
          </Card>

          <Card>
            <CardTitle title="Fundo"></CardTitle>
            <Content>
              <CardBody>
                <Upload
                  onUpload={filesFundo}
                  fileUrl={data.background_url}
                  done={done}
                  errors={errors}
                  setErrors={setErrors}
                />
                <p>{messagesFile}</p>
              </CardBody>
            </Content>
          </Card>
        </Content>

        <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
          <Card>
            <Content>
              <Content>
                <CardBody>
                  <p>Deseja excluir esta coleção de relatórios?</p>

                  <BtnBox>
                    <BtnRed onClick={handleDelete}>Excluir</BtnRed>
                    <BtnWhite onClick={() => setVisibleModal(false)}>Cancelar</BtnWhite>
                  </BtnBox>
                </CardBody>
              </Content>
            </Content>
          </Card>
        </Modal>
      </>
    )
}

export default NewReportCollection